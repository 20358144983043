/* eslint-disable react/no-unescaped-entities */
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { FooterStyled, SocialIconButton } from "./style";
import { Link } from "react-router-dom";
import { clientContactData, footerData } from "common/websiteData";
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import FacebookIcon from "assets/icons/FacebookIcon";
import XIcon from "assets/icons/XIcon";
import InstagramIcon from "assets/icons/InstagramIcon";
import YouTubeIcon from "assets/icons/YouTubeIcon";
import appleStore from "assets/icons/icon-apple.svg";
import playStore from "assets/icons/google-icon.svg";
import { useSelector } from "react-redux";
import moment from "moment";

const Footer = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  const JoinMsz = !isAuthenticated ? (
    <>
      <Typography mb={1} component="h2" fontWeight={700} fontSize={{ xs: 28, md: 30, lg: 32 }} color="inherit">
        We Welcome Your Passion And Expertise
      </Typography>
      <Typography mb={2} component="p" variant="body2" fontSize={{ xs: 16, md: 18, lg: 20 }}>Join our empowering sports community today and grow with us.</Typography>
      <Button component={Link} to="/sign-up" size="large" variant="contained" color="primary" startIcon={<i className="feather-user-plus" />}>Join With Us</Button>
    </>
  ) : (
    <>
        <Typography mb={1} component="h2" fontWeight={700} fontSize={{ xs: 28, md: 30, lg: 32 }} color="inherit">
          Welcome back! We're thrilled to have you with us.
        </Typography>
        <Typography mb={2} component="p" variant="body2" fontSize={{ xs: 16, md: 18, lg: 20 }}>Your passion and expertise enrich our sports community. Let's continue to grow together.</Typography>
        <Button component={Link} to="/dashboard" size="large" variant="contained" color="primary" startIcon={<DashboardCustomizeOutlinedIcon />}>Go to Dashboard</Button>
    </>
  )
  return (
    <FooterStyled component="footer">
      <Container>
        <Box maxWidth={744} mx="auto" color="#fff" className="footer-join aos" data-aos="fade-up">
          {JoinMsz}
        </Box>
        <div className="footer-top">
          <Grid container color={"#fff"} columnSpacing={2} rowSpacing={"20px"} justifyContent="space-between">
            <Grid item xs={12} xl={3}>
              <Typography component="h4" fontWeight={600} mb="25px">
                Have Questions?
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={4} xl={12}>
                  <Typography component="p" variant="body2">
                    Call Us
                  </Typography>
                  <Typography component={Link} color={"#fff"} to={clientContactData.phoneNo.href} fontSize={16}>
                    {clientContactData.phoneNo.label}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={12}>
                  <Typography component="p" variant="body2">
                    Need Live Suppot
                  </Typography>
                  <Typography component={Link} color={"#fff"} to={clientContactData.email.href} fontSize={16}>
                    {clientContactData.email.label}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={4} xl={12}>
                  <Typography component="p" variant="body2">
                    Reach Out Us
                  </Typography>
                  <Typography color={"#fff"} fontSize={16} mb={2}>
                    {clientContactData.address}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" columnGap={1.2} alignItems="center">
                    <SocialIconButton className="facebook" component={Link} to={clientContactData.social.facebook} target="_blank">
                      <FacebookIcon />
                    </SocialIconButton>
                    <SocialIconButton className="twitter" component={Link} to={clientContactData.social.twitter} target="_blank">
                      <XIcon />
                    </SocialIconButton>
                    <SocialIconButton className="instagram" component={Link} to={clientContactData.social.instagram} target="_blank">
                      <InstagramIcon />
                    </SocialIconButton>
                    <SocialIconButton className="youtube" component={Link} to={clientContactData.social.youtube} target="_blank">
                      <YouTubeIcon />
                    </SocialIconButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Typography component="h4" fontWeight={600} mb="25px">
                Quick Links
              </Typography>
              {footerData.quickLinks?.map(({ href, label }, i) => (
                <Box key={`quickLinks_${i}`} className="nav-item">
                  <Typography component={Link} to={href} fontSize={16} className="nav-link">
                    {label}
                  </Typography>
                </Box>
              ))}
            </Grid>
            <Grid item xs={12} sm="auto">
              <Typography component="h4" fontWeight={600} mb="25px">
                Support
              </Typography>
              {footerData.supportLinks?.map(({ href, label }, i) => (
                <Box key={`supportLinks${i}`} className="nav-item">
                  <Typography component={Link} to={href} fontSize={16} className="nav-link">
                    {label}
                  </Typography>
                </Box>
              ))}
            </Grid>
            <Grid item xs={12} sm="auto">
              <Typography component="h4" fontWeight={600} mb="25px">
                Other Links
              </Typography>
              {footerData[ isAuthenticated ? 'otherAuthLinks' : 'otherLinks']?.map(({ href, label }, i) => (
                <Box key={`otherLinks${i}`} className="nav-item">
                  <Typography component={Link} to={href} fontSize={16} className="nav-link">
                    {label}
                  </Typography>
                </Box>
              ))}
            </Grid>
            <Grid item xs={12} md={3} xl={2}>
              <Typography component="h4" fontWeight={600} mb="25px">
                Download
              </Typography>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={"auto"} md={12}>
                  <Box className="nav-item">
                    <Typography component={Link} to={"/"} fontSize={16} target="_blank" className="nav-link">
                      <img className="img-fluid" width={201} height={58} src={appleStore} alt="Apple" />
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={"auto"} md={12}>
                  <Box className="nav-item">
                    <Typography component={Link} to={"/"} fontSize={16} target="_blank" className="nav-link">
                      <img className="img-fluid" width={199} height={58} src={playStore} alt="Google" />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
      <div className="footer-bottom">
        <Container>
          <Typography component="p" variant="body2" textAlign={{ xs: "center", md: "left" }}>
            © {moment(new Date()).year()} Agility Sports - All rights reserved by <Typography component="a" sx={{ '&.MuiTypography-root': { textDecoration: 'underline !important' } }} variant="body2" href="https://bookthecourts.com/" target="_blank" rel="noreferrer">Book The Courts</Typography>.
          </Typography>
        </Container>
      </div>
    </FooterStyled>
  )
}

export default Footer;