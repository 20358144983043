import { Box, styled } from "@mui/material";

import titleHeaderImg from 'assets/images/bg/title-head.png';
import breadcrumbImg from 'assets/images/bg/breadcrumb-bg.jpg';

export const SectionCenterHeadingUI = styled(Box)(({ theme }) => {
  const { breakpoints, functions, palette } = theme;
  const { linearGradient } = functions;
  const { text, white } = palette;
  return {
    textAlign: "center",
    marginBottom: 40,
    color: text.main,
    "&> h2": {
      position: "relative",
      marginBottom: 15,
      fontWeight: 700,
      fontSize: 32,
      zIndex: 1,
      color: "inherit",
      [breakpoints.down("lg")]: {
        fontSize: 30,
      },
      [breakpoints.down("md")]: {
        fontSize: 28,
      },
      "&> span": {
        fontSize: "inherit",
        background: linearGradient("#085A6C -6.68%, #269089 43.13%", "#7ABC82 96.15%", 105.55),
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      },
      "&::after": {
        display: "block",
        content: "''",
        position: "absolute",
        backgroundImage: `url(${titleHeaderImg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        left: 0,
        height: 70,
        bottom: 0,
        zIndex: -1,
        width: "100%",
        top: -16,
        [breakpoints.down("lg")]: {
          top: -10,
        }
      },
    },
    "&> p": {
      maxWidth: 700,
      color: text.light,
    },
    '&.light': {
      color: white.main,
      "&> h2": {
        "&::after": {
          display: "none",
        },
      },
      "&> p": {
        maxWidth: 760,
        color: "inherit",
      },
    },
  }
})

export const SectionUI = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    padding: "80px 0",
    [breakpoints.down("lg")]: {
      padding: "60px 0",
    },
    [breakpoints.down("md")]: {
      padding: "40px 0",
    },
  };
})

export const BreadcrumbHeaderStyled = styled(Box)(({ theme }) => {
  const { breakpoints, palette } = theme;
  const { light } = palette;
  return {
    background: `url(${breadcrumbImg}) rgba(36, 29, 57, 0.8) no-repeat`,
    backgroundSize: "cover",
    backgroundBlendMode: "multiply",
    padding: "60px 0",
    position: "relative",
    overflow: "hidden",
    color: light.main,
    "&::before, &::after": {
      position: "absolute",
      content: '""',
      backgroundImage:
        "linear-gradient(105.55deg, #ec7000 -6.68%, #984b06 43.13%, #ffb87a 96.15%)",
      borderRadius: "100%"
    },
    "&::before": {
      top: 60,
      left: 40,
      width: 20,
      height: 20,
      [breakpoints.down("xxl")]: {
        top: 24,
        left: 24,
      },
    },
    "&::after": {
      top: 30,
      right: 130,
      width: 30,
      height: 30,
    },
    "&> .primary-right-round": {
      content: '""',
      position: "absolute",
      right: 50,
      bottom: 50,
      width: 13,
      height: 13,
      backgroundImage:
        "linear-gradient(105.55deg, #ec7000 -6.68%, #984b06 43.13%, #ffb87a 96.15%)",
      borderRadius: 13,
      [breakpoints.down("xxl")]: {
        top: 80,
      },
    },
    "&> .breadcrumb-box": {
      position: "relative",
      "&::after": {
        position: "absolute",
        right: 0,
        bottom: -60,
        width: 147,
        height: 74,
        content: '""',
        background:
          "linear-gradient(105.55deg, #ec7000 -6.68%, #984b06 43.13%, #ffb87a 96.15%)",
        borderTopLeftRadius: 84,
        borderTopRightRadius: 84,
        [breakpoints.down("md")]: {
          width: 107,
          height: 52,
        },
      }
    }
  };
});