import { capitalize } from '@mui/material';
import Footer from 'common/footer/Footer';
import Navbar from 'common/navbar/Navbar';
import UserNav from 'common/navbar/UserNav';
import InternetNotAvailableSnackbar from 'common/no-internet-alert';
import Preloader from 'common/preloader';
import BreadcrumbHeader from 'containers/headers/BreadcrumbHeader';
import { ResetScroll } from 'helper';
import NotFound from 'pages/not-found';
import { Suspense } from 'react'
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

const AuthUserRoot = () => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useSelector(state => state.auth);
  if (!isAuthenticated) {
    return <NotFound />;
  }
  const page = pathname.split("/")[1].split("-").join(" ");
  const pageName = capitalize(page);
  return (
    <>
      <Helmet>
        <title>Agility Sports - {pageName}</title>
      </Helmet>
      <ResetScroll />
      <InternetNotAvailableSnackbar />
      <Suspense fallback={<Preloader />}>
        <Navbar />
        <main>
          <BreadcrumbHeader currentPage={pageName} />
          <UserNav />
          <Outlet />
        </main>
        <Footer />
      </Suspense>
    </>
  )
}

export default AuthUserRoot;