import { AppBar, Box, Button, Drawer, styled } from "@mui/material";
import menuActiveIcon from 'assets/icons/menu-active.svg';
// import useActivePath from "hooks/useActivePath";
import { SM } from "theme/components/container";

export const CustomAppBar = styled(AppBar)(({ theme }) => ({
  "& .nav-brand": {
    width: 195,
  },
  justifyContent: 'center',
  "&.header-dark": {
    transition: "background-color .2s ease",
    backgroundColor: theme.palette.transparent.main,
    "&.scrolled": {
      backgroundColor: "rgb(23, 124, 130)",
    },
    "& .nav-link": {
      color: "#fff",
    },
  },
  "&.header-light": {
    height: 88,
    backgroundColor: "#494848",
    "&.scrolled": {
      backgroundColor: "#494848",
    },
  },
  "& .nav-link": {
    position: "relative",
    color: theme.palette.white.main,
    transition: "all 0.5s ease-in-out 0s",
    "&:hover": {
      color: "#ec7000",
    },
    "&::before": {
      content: "''",
      top: -16,
      backgroundImage: `url(${menuActiveIcon})`,
      width: 17,
      height: 17,
      opacity: 0,
      position: "absolute",
      left: "50%",
      WebkitTransform: "translateX(-50%)",
      MsTransform: "translateX(-50%)",
      transform: "translate(-50%)",
      pointerEvent: "none"
    },
    "&.active": {
      color: "#ec7000",
      "&::before": {
        opacity: 1,
      },
    },
  },
  "&.scrolled": {
    boxShadow: "0 3px 5px rgba(57, 63, 72, 0.3)",
  },
}));

export const LoginButton = styled(Button)(({ theme }) => {
  // const isHomePage = useActivePath("/");
  const isHomePage = false;
  return {
    display: "none",
    color: isHomePage ? theme.palette.primary.main : theme.palette.light.main,
    backgroundColor: isHomePage ? theme.palette.light.main : theme.palette.primary.main,
    boxShadow: "none",
    [SM]: {
      display: "inline-flex"
    },
    "&:hover, :focus:not(:hover)": {
      color: theme.palette.light.main,
      backgroundColor: theme.palette.text.main,
      boxShadow: "none",
    }
  }
});

export const NavDrawer = styled(Drawer)(({ theme }) => {
  return {
    "& .mobileNavDrawer": {
      minWidth: 260,
      backgroundColor: '#494848',
    },
    "& .nav-wrap": {
      backgroundColor: '#494848',
    },
    "& .mobile-nav": {
      color: theme.palette.light.main,
      height: "100%",
      "&> div": {
        width: "100%",
        "&> .nav-link": {
          display: "block",
          fontSize: "1rem",
          fontWeight: "500",
          color: theme.palette.light.main,
          padding: "12px 15px",
          transition: "all 0.5s ease-in-out 0s",
          "&:hover": {
            color: "#ec7000",
          },
          "&.active": {
            color: "#ec7000",
          },
        }
      }
    }
  }
});

export const ScrollTopArrowUI = styled(Box)(({ theme }) => {
  const { palette } = theme;
  return {
    position: 'fixed',
    bottom: 20,
    right: 20,
    height: 35,
    width: 35,
    cursor: "pointer",
    display: "block",
    borderRadius: "50%",
    boxShadow: "inset 0 0 0 2px rgba(75, 64, 237, 0.2)",
    zIndex: 99,
    "&> svg.progress-circle path": {
      stroke: palette.primary.main,
      strokeWidth: 4,
      boxZizing: "border-box",
      transition: "all 200ms linear",
      fill: "none"
    },
    "&> .up-arrow": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    }
  }
});

export const UserNavBox = styled(Box)(({ theme }) => ({
  '& .nav-link': {
    gap: 6,
    flexDirection: "column",
    color: theme.palette.text.main,
    background: theme.palette.light.main,
    border: '1px solid #EAEDF0',
    fontWeight: 500,
    borderRadius: 10,
    transition: "all 0.5s ease-in-out 0s",
    lineHeight: "normal",
    cursor: 'pointer',
    '&> img': {
      transition: "all 0.5s ease-in-out 0s",
    },
    '&> svg': {
      transition: "all 0.5s ease-in-out 0s",
    },
    '&:hover, &.active': {
      color: theme.palette.light.main,
      background: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      '&> img': {
        filter: 'invert(1) brightness(100)',
      },
      '&> svg': {
        fill: '#fff',
        stroke: '#fff',
      },
    },
  },
}));