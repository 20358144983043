import { appendZero } from "helper";
import dashboardIcon from 'assets/icons/dashboard-icon.svg';
import bookingIcon from 'assets/icons/booking-icon.svg';
// import invoiceIcon from 'assets/icons/invoice-icon.svg';
import profileIcon from 'assets/icons/profile-icon.svg';
import settingsIcon from 'assets/icons/settings-icon.svg';
import dayjs from "dayjs";
import levelOne from "assets/icons/level-1.svg";
import levelTwo from "assets/icons/level-2.svg";
import levelThree from "assets/icons/level-3.svg";

export const mainNavLinks = [
  {
    id: "home",
    href: "https://agilitysports.ca/",
    label: "Home"
  },
  // {
  //   id: "venues",
  //   href: "/venues",
  //   label: "Venues"
  // },
  {
    id: "book-court",
    href: "/",
    label: "Book a Court"
  },
  // {
  //   id: "private-team-lessons",
  //   href: "/private-team-lessons",
  //   label: "Training"
  // },
  // {
  //   id: "pickleball-booking-query",
  //   href: "/pickleball-booking-query",
  //   label: "Pickleball"
  // },
  // {
  //   id: "table-tennis-booking-query",
  //   href: "/table-tennis-booking-query",
  //   label: "Table tennis"
  // },
  {
    id: "membership",
    href: "/membership",
    label: "Membership"
  },
  {
    id: "events",
    href: "https://agilitysports.ca/events/",
    label: "Events"
  },
  {
    id: "contact-us",
    href: "https://agilitysports.ca/contact-us/",
    label: "Contact Us"
  },
];

export const mainNavMobileLinks = [
  {
    id: "home",
    href: "https://agilitysports.ca/",
    label: "Home"
  },
  // {
  //   id: "venues",
  //   href: "/venues",
  //   label: "Venues"
  // },
  {
    id: "book-court",
    href: "/",
    label: "Book a Court"
  },
  // {
  //   id: "private-team-lessons",
  //   href: "/private-team-lessons",
  //   label: "Training"
  // },
  // {
  //   id: "pickleball-booking-query",
  //   href: "/pickleball-booking-query",
  //   label: "Pickleball"
  // },
  // {
  //   id: "table-tennis-booking-query",
  //   href: "/table-tennis-booking-query",
  //   label: "Table tennis"
  // },
  {
    id: "membership",
    href: "/membership",
    label: "Membership"
  },
  {
    id: "events",
    href: "https://agilitysports.ca/events/",
    label: "Events"
  },
  {
    id: "contact-us",
    href: "https://agilitysports.ca/contact-us/",
    label: "Contact Us"
  },
  {
    id: "sign-up",
    href: "/sign-up",
    label: "Sign Up"
  },
  {
    id: "signin",
    href: "/login",
    label: "Sign In"
  },
];

export const footerData = {
  quickLinks: [
    {
      href: "https://agilitysports.ca/",
      label: "Home",
    },
    // {
    //   href: "/venues",
    //   label: "Venues",
    // },
    {
      href: "/",
      label: "Book a Court",
    },
    // {
    //   href: "/private-team-lessons",
    //   label: "Training"
    // },
    {
      href: "/membership",
      label: "Membership"
    },
    {
      href: "https://agilitysports.ca/events/",
      label: "Events"
    },

  ],
  supportLinks: [
    {
      href: "https://agilitysports.ca/contact-us/",
      label: "Contact Us",
    },
    {
      href: "/faqs",
      label: "FAQ's",
    },
    {
      href: "/privacy-policy",
      label: "Privacy Policy",
    },
    {
      href: "https://agilitysports.ca/terms/",
      label: "Terms & Conditions",
    },
  ],
  otherLinks: [
    {
      href: "/login",
      label: "Sign In",
    },
    {
      href: "/sign-up",
      label: "Sign Up",
    },
    // {
    //   href: "/booking-query",
    //   label: "Booking Query",
    // },
    // {
    //   href: "/pickleball-booking-query",
    //   label: "Pickleball"
    // },
    // {
    //   href: "/table-tennis-booking-query",
    //   label: "Table tennis"
    // },
  ],
  otherAuthLinks: [
    {
      href: "/dashboard",
      label: "Dashboard",
    },
    {
      href: "/my-bookings?tab=0",
      label: "My Bookings",
    },
    {
      href: "/profile",
      label: "My Profile",
    },
    // {
    //   href: "/booking-query",
    //   label: "Booking Query",
    // },
    // {
    //   href: "/pickleball-booking-query",
    //   label: "Pickleball"
    // },
    // {
    //   href: "/table-tennis-booking-query",
    //   label: "Table tennis"
    // },
  ],
}

export const taxData = {
  tax: 13,
  taxMassage: `Taxes & fees include of 13% HST.`,
}

export const clientContactData = {
  phoneNo: {
    href: "tel:+16476290966",
    label: "+1 (647) 629-0966",
  },
  email: {
    href: "mailto:info@agilitysports.ca",
    label: "info@agilitysports.ca",
  },
  email_2: {
    href: "mailto:info@agilitysports.com",
    label: "info@agilitysports.com",
  },
  address: <>Unit 7, 68 Eastern Avenue, <br />Brampton, ON, L6W 1X8</>,
  addressStr: "Unit 7, 68 Eastern Avenue, Brampton, ON, L6W 1X8",
  social: {
    facebook: "#",
    instagram: "#",
    twitter: "#",
    youtube: "#",
  }
}

export const userNavData = [
  {
    id: "dashboard",
    href: "/dashboard",
    label: "Dashboard",
    icon: dashboardIcon,
  },
  {
    id: 'my-bookings',
    href: "/my-bookings?tab=0",
    label: "My Bookings",
    icon: bookingIcon,
  },
  // {
  //   id: 'invoice',
  //   href: "/invoice",
  //   label: "Invoice",
  //   icon: invoiceIcon,
  // },
  {
    id: 'profile',
    href: "/profile",
    label: "Profile",
    icon: profileIcon,
  },
  {
    id: 'profile-settings',
    href: "/profile-settings",
    label: "Settings",
    icon: settingsIcon,
  },
];

export const profileSettingsNavData = [
  {
    key: "edit-profile",
    label: "Edit Profile",
  },
  {
    key: "change-password",
    label: "Change Password",
  },
];

export const bookingsNavData = [
  {
    key: "upcoming",
    label: "Upcoming",
  },
  {
    key: "completed",
    label: "Completed",
  },
  {
    key: "on-going",
    label: "On Going",
  },
  {
    key: "cancelled",
    label: "Cancelled",
  },
];

export const expertiseData = [
  {
    value: 1,
    label: "Beginner",
  },
  {
    value: 2,
    label: "Intermediate",
  },
  {
    value: 3,
    label: "Master",
  },
];

export const enrolledForData = [
  {
    value: 1,
    label: 'Court Rental',
  },
  {
    value: 2,
    label: 'Coaching',
  },
];

export const dobMaxDate = dayjs().subtract(7, 'year');

export function generateTimeArray() {
  const timeArray = [];
  let idCounter = 1;

  for (let hour = 6; hour <= 23; hour++) {

    timeArray.push({
      id: idCounter,
      value: hour,
      label: `${hour <= 12 ? appendZero(hour) : appendZero(hour - 12)}:00 ${hour < 12 ? 'AM' : 'PM'}`,
    });

    idCounter++;
  }

  return timeArray;
}

export const planLevelIcons = {
  1: levelOne,
  2: levelTwo,
  3: levelThree,
}


export const specialDates = [
  '2024-05-20'
]